import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import _ from 'lodash'
import qs from 'query-string'
import { ToolbarItem } from 'components/ActionToolbar'

import CloudBadge from 'components/CloudBadge'
import Button from 'components/Button'
import DataTable from 'components/DataTable'
import Card, { CardBody } from 'components/Card'
import Icon from 'components/Icon'
import RedirectWithoutPermission from 'containers/PermissionChecker/RedirectWithoutPermission'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Popover, { PopoverBody, PopoverAnchor } from 'components/Popover'
import { exists } from 'utils/sonraiUtils'
import BotManagementFilterPanel from './BotManagementFilterPanel'
import TextLink from 'components/TextLink'
import IHelp from 'containers/IHelp'
import SectionHeader from 'components/SectionHeader'
import permissionChecker from 'containers/PermissionChecker'

import {
  getBots,
  createBot,
  validateSourceUrl,
  clearSourceUrlValidation,
  handleDeleteBots,
  getExemptedIdentities,
  addExemptedIdentities,
  deleteExemptedIdentities,
  setExemptedIdentitiesError,
} from 'containers/SonraiData/actions'
import {
  selectBots,
  selectIsCreatingBot,
  selectSourceUrlValidation,
  selectExemptedIdentities,
  selectExemptedIdentitiesError,
  selectExemptedIdentitiesLoading,
  selectExemptedIdentitiesAdding,
  selectExemptedIdentitiesDeleting,
} from 'containers/SonraiData/selectors'
import CreateBotModal from './CreateBotModal'
import ExemptedIdentityModal from './ExemptedIdentityModal'

class BotManagement extends Component {
  constructor(props) {
    super(props)

    props.getBots({ filters: {} })

    this.state = {
      isModalOpen: false,
      isExemptedModalOpen: false,
      showFilterPanel: false,
      filters: {
        cloud: null,
        status: null,
      },
      checkboxSelectedRows: [],
    }

    this.styles = {
      container: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        padding: '1rem',
        width: '100%',
        height: '100%',
      },
      header: {
        display: 'grid',
        gridTemplateColumns: '1fr auto auto',
      },
      headerExt: {
        display: 'flex',
        flexDirection: 'column',
      },
      title: {
        fontSize: '1.3rem',
        margin: '0rem 0rem 0.5rem 0rem',
      },
      description: {
        color: 'rgb(128, 124, 124)',
      },
      content: {
        margin: '1rem 0rem',
      },
      buttonContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '0rem 0rem 0rem 1rem',
      },
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.filters, this.state.filters)) {
      const { filters } = this.state
      this.props.getBots({ filters })
    }
  }

  toggleCreateBotModal = () => {
    this.setState(oldState => ({ isModalOpen: !oldState.isModalOpen }))
  }

  toggleExemptedModal = () => {
    this.setState(oldState => ({
      isExemptedModalOpen: !oldState.isExemptedModalOpen,
    }))
  }

  handleNavToBotDetail = ({ srn }) => {
    this.props.push({
      pathname: '/App/BotManagement/BotDetails',
      search: qs.stringify({
        botId: srn,
      }),
    })
  }

  toggleFilterPanel = () => {
    this.setState(currentState => ({
      showAddPopover: !currentState.showAddPopover,
    }))
  }

  onFilterPanelToggle = newVisibility => {
    this.setState({
      showFilterPanel: newVisibility,
    })
  }

  handleFilterChange = ({ cloud, botStatus }) => {
    const filters = { cloud, status: botStatus }
    this.setState({ filters })
  }

  handleClearFilters = () => {
    const filters = { cloud: null, status: null }
    this.setState({
      filters,
      showFilterPanel: false,
    })
  }

  getFilteredBots = () => {
    const bots = this.props.bots.get('data').toJS()

    return bots.map(bot => ({
      title: bot.title,
      type: bot.type,
      cloud: bot.cloud,
      source: bot.isCustom ? 'Custom' : 'Sonrai',
      status: bot.status,
      lastSync: bot.lastSync,
      sourceUrl: bot.url,
      srn: bot.srn,
      resourceId: bot.resourceId,
    }))
  }

  getStatusColor = status => {
    if (status == 'FAILED_TO_SYNC') {
      return this.props.theme.red
    } else {
      return this.props.theme.success
    }
  }

  getHelpIcon = () => (
    <div style={{ padding: '2px', marginLeft: '0.4rem' }}>
      <IHelp
        id={`sonraiBotHelpIcon`}
        width="500px"
        info
        infoMsg={
          <div
            style={{
              padding: '0.25rem',
            }}
          >
            <p>
              Sonrai Bots are agents you can configure and deploy into your
              cloud accounts, that allow the Sonrai to effect changes in your
              cloud environment to address issues reported as outside policy.{' '}
            </p>
            <p>
              Bots come in two styles:
              <ul
                style={{
                  listStyleType: 'disc',
                  listStylePosition: 'inside',
                  margin: '0.25rem 0rem',
                }}
              >
                <li>Remediation Bots</li>
                <li>Prevention Bots</li>
              </ul>
            </p>
            <p>
              <strong>Remediation Bots</strong>, upon being alerted to a
              violation of policy, will update a resource to bring that resource
              back in line with accepted policies. Changes can include things
              like network security group changes, resource creation, encryption
              setting changes, use of unauthorized cloud services, etc.
            </p>
            <p>
              <strong>Prevention Bots</strong> have the ability to update
              policies within your cloud account, to restrict access to cloud
              services, applying Service Control Policies in AWS, Policies in
              Azure and Service Usage in GCP. Bots are granted access to your
              cloud account with an appropriate role in each account you wish to
              have managed.
            </p>
            <p>
              For more details on the role configurations, please refer to
              the&nbsp;
              <TextLink
                to={{
                  pathname: '/App/HelpCenter',
                  search: qs.stringify({
                    preloadSearch: 'bots',
                  }),
                }}
              >
                Bots Documentation
              </TextLink>
              &nbsp;in the Knowledge Center .
            </p>
          </div>
        }
        position="right"
      />
    </div>
  )

  handleDeleteBots = () => {
    //arr of srns to delete
    const bots = this.state.checkboxSelectedRows.map(item => item.srn)
    this.setState({
      checkboxSelectedRows: [],
    })
    this.props.handleDeleteBots({ bots })
  }

  handleSelectionChange = evnt => {
    const rows = evnt.api.getSelectedRows() || []
    this.setState({ checkboxSelectedRows: rows })
  }

  checkboxRowTest = params => {
    //It's a grouped row
    if (!params.data) {
      return false
    }

    return this.props.userHasPermission({
      permissionName: 'edit.bots',
      resourceId: params.data.resourceId,
    })
  }

  render() {
    const hasFiltersApplied = exists(
      _.keys(this.state.filters).find(
        name => !_.isEmpty(this.state.filters[name])
      )
    )

    return (
      <div style={this.styles.container}>
        <RedirectWithoutPermission permissionName="edit.bots" />
        <div style={this.styles.header}>
          <div style={this.styles.headerExt}>
            <div style={{ display: 'flex' }}>
              <SectionHeader>Bots</SectionHeader>
              {this.getHelpIcon()}
            </div>
            <div style={this.styles.description}>
              Sonrai Bots are agents you can configure and deploy into your
              cloud accounts, that allow the Sonrai to effect changes in your
              cloud environment to address issues reported as outside policy.
            </div>
          </div>
          <div style={this.styles.buttonContainer}>
            <Button
              color="primary"
              onClick={this.toggleExemptedModal}
              outline
              disabled={this.props.isCreatingBot}
            >
              {this.props.isCreatingBot ? (
                <Icon fa spin name="sync" style={{ fontSize: '1.1rem' }} />
              ) : (
                <span>
                  <Icon fa name="lock" /> Manage Exempted Identities
                </span>
              )}
            </Button>
          </div>
          <div style={this.styles.buttonContainer}>
            <WithPermission
              permissionName="edit.bots"
              resourceId={({ org }) => `/org/${org}/`}
            >
              <Button
                color="primary"
                onClick={this.toggleCreateBotModal}
                disabled={this.props.isCreatingBot}
              >
                {this.props.isCreatingBot ? (
                  <Icon fa spin name="sync" style={{ fontSize: '1.1rem' }} />
                ) : (
                  <span>
                    <Icon fa name="plus" /> New Bot
                  </span>
                )}
              </Button>
            </WithPermission>
          </div>
        </div>
        <div style={this.styles.content}>
          <Card loading={this.props.bots.get('isLoading')}>
            <CardBody>
              <DataTable
                supressSelectAll
                hasCheckBoxes
                selectionChanged={this.handleSelectionChange}
                checkboxSelectedRows={this.state.checkboxSelectedRows}
                bulkActions={[
                  <ToolbarItem
                    key="delete"
                    permissionName="edit.bots"
                    iconName={'trash'}
                    onClick={this.handleDeleteBots}
                    disabled={this.state.checkboxSelectedRows.length === 0}
                    color="#fff"
                  >
                    Delete Bot(s)
                  </ToolbarItem>,
                ]}
                bulkActionWorking={false}
                autoSize
                customFilter={() => (
                  <Popover
                    isOpen={this.state.showFilterPanel}
                    onToggle={this.onFilterPanelToggle}
                    position="right-start"
                  >
                    <PopoverAnchor>
                      <div style={{ margin: '1rem 0rem 0rem 0.75rem' }}>
                        <Icon
                          fa
                          name={hasFiltersApplied ? 'filter-solid' : 'filter'}
                          style={{ color: this.props.theme.primary }}
                        />
                      </div>
                    </PopoverAnchor>
                    <PopoverBody>
                      <BotManagementFilterPanel
                        hasFiltersApplied={hasFiltersApplied}
                        filters={this.state.filters}
                        handleFilterChange={this.handleFilterChange}
                        handleClearFilters={this.handleClearFilters}
                        closeFilterPanel={() =>
                          this.setState({
                            showFilterPanel: false,
                          })
                        }
                      />
                    </PopoverBody>
                  </Popover>
                )}
                customColumnConfig={{
                  title: {
                    checkboxSelection: this.checkboxRowTest,
                  },
                  resourceId: {
                    hide: true,
                  },
                  cloud: {
                    cellRendererFramework: ({ value }) => {
                      if (!value) {
                        return null
                      }

                      return (
                        <span>
                          <CloudBadge type={value ? value.toLowerCase() : ''} />{' '}
                          {value}
                        </span>
                      )
                    },
                  },
                  sourceUrl: {
                    width: 400,
                    minWidth: 400,
                    cellRendererFramework: ({ value }) => {
                      if (!value) {
                        return null
                      }

                      return (
                        <TextLink
                          color="primary"
                          style={{
                            color: this.props.theme.primary,
                          }}
                          onClick={() => window.open(value, '_blank')}
                        >
                          {value}
                        </TextLink>
                      )
                    },
                  },
                  status: {
                    cellRendererFramework: ({ value }) => {
                      if (!value) {
                        return null
                      }

                      return (
                        <div
                          style={{
                            color: this.getStatusColor(value),
                            fontWeight: '400',
                          }}
                        >
                          {value}
                        </div>
                      )
                    },
                  },
                }}
                hiddenColumns={['srn', 'version']}
                onDoubleClickRow={this.handleNavToBotDetail}
                data={this.getFilteredBots()}
                isLoading={this.props.bots.get('isLoading')}
              />
            </CardBody>
          </Card>
        </div>
        <CreateBotModal
          clearSourceUrlValidation={this.props.clearSourceUrlValidation}
          isValidating={this.props.sourceUrlValidation.get('isValidating')}
          isValid={this.props.sourceUrlValidation.get('isValid')}
          validateSourceUrl={this.props.validateSourceUrl}
          createBot={this.props.createBot}
          isOpen={this.state.isModalOpen}
          toggle={this.toggleCreateBotModal}
        />
        {this.state.isExemptedModalOpen && (
          <ExemptedIdentityModal
            isOpen={this.state.isExemptedModalOpen}
            toggle={this.toggleExemptedModal}
            getExemptedIdentities={this.props.getExemptedIdentities}
            exemptedIdentities={this.props.exemptedIdentities}
            loading={this.props.exemptedIdentitiesLoading}
            error={this.props.exemptedIdentitiesError}
            add={this.props.addExemptedIdentities}
            adding={this.props.exemptedIdentitiesAdding}
            deleting={this.props.exemptedIdentitiesDeleting}
            delete={this.props.deleteExemptedIdentities}
            setError={this.props.setExemptedIdentitiesError}
          />
        )}
      </div>
    )
  }
}

BotManagement.propTypes = {
  bots: ImmutablePropTypes.iterable,
  getBots: PropTypes.func,
  createBot: PropTypes.func,
  isCreatingBot: PropTypes.bool,
  push: PropTypes.func,
  theme: themeShape,
  userHasPermission: PropTypes.func,
  sourceUrlValidation: ImmutablePropTypes.map,
  validateSourceUrl: PropTypes.func,
  clearSourceUrlValidation: PropTypes.func,
  handleDeleteBots: PropTypes.func,
  getExemptedIdentities: PropTypes.func,
  addExemptedIdentities: PropTypes.func,
  deleteExemptedIdentities: PropTypes.func,
  setExemptedIdentitiesError: PropTypes.func,
  exemptedIdentities: ImmutablePropTypes.list,
  exemptedIdentitiesDeleting: ImmutablePropTypes.map,
  exemptedIdentitiesAdding: PropTypes.bool,
  exemptedIdentitiesLoading: PropTypes.bool,
  exemptedIdentitiesError: PropTypes.string,
}

const mapStateToProps = createStructuredSelector({
  bots: selectBots,
  isCreatingBot: selectIsCreatingBot,
  sourceUrlValidation: selectSourceUrlValidation,
  exemptedIdentities: selectExemptedIdentities,
  exemptedIdentitiesLoading: selectExemptedIdentitiesLoading,
  exemptedIdentitiesError: selectExemptedIdentitiesError,
  exemptedIdentitiesAdding: selectExemptedIdentitiesAdding,
  exemptedIdentitiesDeleting: selectExemptedIdentitiesDeleting,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
      getBots,
      createBot,
      validateSourceUrl,
      clearSourceUrlValidation,
      handleDeleteBots,
      getExemptedIdentities,
      addExemptedIdentities,
      deleteExemptedIdentities,
      setExemptedIdentitiesError,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, permissionChecker, themeable)(BotManagement)
