import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Input,
  FormGroup,
  FormFeedback,
} from 'reactstrap'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import TextLink from 'components/TextLink'
import Select from 'react-select'
import Button from 'components/Button'
import IHelp from 'containers/IHelp'
import DataTable from 'components/DataTable'
import Icon from 'components/Icon'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'

const cloudOptions = [{ value: 'aws', label: 'AWS' }]

class ExemptedIdentityModal extends Component {
  state = {
    newExemptedIds: '',
  }
  styles = {
    modalBody: {
      maxHeight: '80vh',
      overflow: 'auto',
    },
    error: {
      fontSize: '0.8rem',
      fontWeight: '400',
      margin: '0.25rem 0rem 0.5rem 0.25rem',
      color: this.props.theme.red,
    },
    success: {
      fontSize: '0.8rem',
      fontWeight: '400',
      margin: '0.25rem 0rem 0.5rem 0.25rem',
    },
  }

  constructor(props) {
    super(props)
    this.state = {
      errorMsg: null,
      newCloudType: cloudOptions[0],
    }
    if (props.exemptedIdentities.isEmpty()) {
      props.getExemptedIdentities()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.adding && !this.props.adding && !this.props.error) {
      this.setState({ newExemptedIds: '' })
    }
    if (this.props.error && !prevState.errorMsg) {
      this.setError(this.props.error)
    }
  }

  handleCloudChange = params => {
    this.setState({ newCloudType: params })
  }

  closeError = () => {
    this.setState({ errorMsg: null })
  }

  setError = msg => {
    this.setState({
      errorMsg: msg,
    })
    this.props.setError({ error: null })
    setTimeout(() => {
      this.setState({ errorMsg: null })
    }, 10000)
  }

  addExemptedIDs = () => {
    if (this.state.newExemptedIds.length > 0) {
      const newExemptItems = this.state.newExemptedIds.trim().split(',')
      this.props.add({
        resourceIds: newExemptItems,
        cloudType: this.state.newCloudType.value,
      })
    }
  }

  getData = () => {
    return this.props.exemptedIdentities.toJS().map(id => ({
      resourceId: id.resourceId,
      cloudType: id.cloud,
      delete: id.resourceId,
    }))
  }

  deleteExemptedIdentity = resourceId => {
    if (!this.props.deleting.get(resourceId)) {
      this.props.delete({ resourceIds: [resourceId] })
    }
  }

  idsAreValid = () => {
    let isValid = true

    if (!this.state.newExemptedIds) {
      return isValid
    }

    if (this.state.newCloudType.value === 'aws') {
      const newExemptItems = this.state.newExemptedIds.trim().split(',')
      newExemptItems.forEach(id => {
        if (!id.startsWith('arn:')) {
          isValid = false
        }
      })
    }

    return isValid
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle}>
          Manage Bot Exemptions
        </ModalHeader>
        <ModalBody style={this.styles.modalBody}>
          <div style={{ margin: '0rem 0rem 1rem 0rem' }}>
            {this.state.errorMsg && (
              <Alert color="danger" toggle={this.closeError}>
                {this.state.errorMsg}
              </Alert>
            )}
            <p>
              Exempted Identities are identities that are exempt from the
              policies enforced by Sonrai Prevention Bots.
            </p>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                columnGap: ' 0.5em',
              }}
            >
              <div>Add New Exempted Identities</div>
              <div>
                <IHelp
                  width="500px"
                  info
                  infoMsg={`
Enter multiple identities by separating each Resource ID with a comma.
Currently only AWS Role ARNs are supported.
                  `}
                />
              </div>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 210px',
                columnGap: '10px',
              }}
            >
              <div>
                <FormGroup>
                  <Input
                    type="text"
                    onChange={e =>
                      this.setState({ newExemptedIds: e.target.value })
                    }
                    value={this.state.newExemptedIds}
                    disabled={this.props.adding}
                    placeholder={`Enter the Resource ID for the Identity you want to exempt...`}
                    invalid={!this.idsAreValid()}
                  />
                  <FormFeedback invalid>
                    {this.state.newCloudType.value === 'aws'
                      ? 'AWS resource IDs must start with "arn:"'
                      : ''}
                  </FormFeedback>
                </FormGroup>
              </div>
              <div>
                <Select
                  options={cloudOptions}
                  onChange={this.handleCloudChange}
                  placeholder={`Select a cloud type...`}
                  isDisabled={this.props.adding}
                  value={this.state.newCloudType}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '0.5em',
              }}
            >
              <Button
                disabled={
                  this.props.adding ||
                  !this.state.newExemptedIds ||
                  !this.state.newCloudType ||
                  !this.idsAreValid()
                }
                color="primary"
                onClick={this.addExemptedIDs}
              >
                {this.props.adding ? (
                  <Icon fa spin name="sync" />
                ) : (
                  <Icon fa name="plus" />
                )}
                &nbsp;Add Identities
              </Button>
            </div>
            <div>Current Exempted Identities</div>
            <div style={{ height: '500px', marginTop: '1em' }}>
              {this.props.loading ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <SquareLoadingAnimation />
                </div>
              ) : (
                <DataTable
                  autosize={false}
                  customColumnConfig={{
                    resourceId: {
                      flex: 1,
                    },
                    delete: {
                      width: 100,
                      minWidth: 100,
                      pinned: 'right',
                      headerName: '',
                      enableRowGroup: false,
                      menuTabs: [],
                      suppressMenu: true,
                      cellRendererFramework: params => {
                        return (
                          <TextLink
                            color="primary"
                            style={{
                              color: this.props.theme.primary,
                            }}
                            onClick={() => {
                              this.deleteExemptedIdentity(params.data.delete)
                            }}
                            disabled={this.props.deleting.get(
                              params.data.resourceId
                            )}
                          >
                            Delete
                          </TextLink>
                        )
                      },
                    },
                  }}
                  data={this.getData()}
                />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <TextLink color="primary" onClick={this.props.toggle}>
            Close
          </TextLink>
        </ModalFooter>
      </Modal>
    )
  }
}

ExemptedIdentityModal.propTypes = {
  toggle: PropTypes.func,
  getExemptedIdentities: PropTypes.func,
  add: PropTypes.func,
  delete: PropTypes.func,
  setError: PropTypes.func,
  exemptedIdentities: ImmutablePropTypes.list,
  loading: PropTypes.bool,
  deleting: ImmutablePropTypes.map,
  adding: PropTypes.bool,
  error: PropTypes.string,
  isOpen: PropTypes.bool,
  theme: themeShape,
}

export default themeable(ExemptedIdentityModal)
