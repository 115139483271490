import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SelectBar from 'components/SelectBar'
import TextLink from 'components/TextLink'
import _ from 'lodash'
import Button from 'components/Button'

class BotManagementFilterPanel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cloud: props.filters.cloud,
      botStatus: props.filters.status,
    }
  }
  render() {
    const { cloud, botStatus } = this.state
    return (
      <div style={{ padding: '0.5rem', width: '300px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ fontWeight: '400', fontSize: '1.1rem' }}> Filters</div>
          <TextLink
            style={{ fontSize: '0.9rem', cursor: 'pointer' }}
            color="primary"
            onClick={this.props.handleClearFilters}
            disabled={!this.props.hasFiltersApplied}
          >
            Reset Filters
          </TextLink>
        </div>
        <div style={{ margin: '1rem 0rem' }}>
          <div style={{ fontWeight: '400' }}> Cloud </div>
          <SelectBar
            isClearable
            placeholder="Select a cloud..."
            value={this.state.cloud}
            onChange={cloud => this.setState({ cloud })}
            options={[
              { label: 'AWS', value: 'AWS' },
              { label: 'AZURE', value: 'Azure' },
              { label: 'GCP', value: 'GCP' },
            ]}
          />
        </div>
        <div style={{ margin: '1rem 0rem' }}>
          <div style={{ fontWeight: '400' }}> Status </div>
          <SelectBar
            isClearable
            placeholder="Select a status..."
            value={this.state.botStatus}
            onChange={botStatus => this.setState({ botStatus })}
            options={[
              { label: 'OK', value: 'OK' },
              { label: 'FAILED_TO_SYNC', value: 'FAILED_TO_SYNC' },
            ]}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <TextLink
            style={{
              fontSize: '0.9rem',
              cursor: 'pointer',
              color: '#ABABAB',
              margin: '0rem 0.75rem 0rem 0rem',
            }}
            onClick={() => this.props.closeFilterPanel()}
          >
            Close
          </TextLink>
          <Button
            disabled={_.isEqual(this.props.filters, {
              cloud,
              status: botStatus,
            })}
            color="primary"
            onClick={() => this.props.handleFilterChange({ cloud, botStatus })}
          >
            Apply
          </Button>
        </div>
      </div>
    )
  }
}

BotManagementFilterPanel.propTypes = {
  handleClearFilters: PropTypes.func,
  filters: PropTypes.object,
  handleFilterChange: PropTypes.func,
  hasFiltersApplied: PropTypes.bool,
  closeFilterPanel: PropTypes.func,
}

export default BotManagementFilterPanel
