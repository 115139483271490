import { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import permissionChecker from './index'

export class RedirectWithoutPermission extends Component {
  componentDidMount() {
    this.redirectWithoutPermission()
  }

  componentDidUpdate() {
    this.redirectWithoutPermission()
  }

  redirectWithoutPermission = () => {
    if (!this.props.permissions.get('loading')) {
      if (this.props.multiPermissions) {
        let hasAny = false
        this.props.multiPermissions.forEach(permission => {
          if (
            this.props.userHasPermission({
              permissionName: permission.permissionName,
              resourceId: permission.resourceId,
              swimlanes: permission.swimlanes,
            })
          ) {
            hasAny = true
          }
        })
        if (!hasAny) {
          this.props.push(this.props.to)
        }
      } else {
        const hasPermission = this.props.userHasPermission({
          permissionName: this.props.permissionName,
          resourceId: this.props.resourceId,
          swimlanes: this.props.swimlanes,
        })
        if (!hasPermission) {
          this.props.push(this.props.to)
        }
      }
    }
  }

  render() {
    return null
  }
}

RedirectWithoutPermission.defaultProps = {
  to: '/App/SolutionCenter',
}

RedirectWithoutPermission.propTypes = {
  permissions: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
  }).isRequired,
  permissionName: PropTypes.string,
  resourceId: PropTypes.string,
  swimlanes: PropTypes.arrayOf(PropTypes.string),

  // if you have multiple permissions, passs it this bad boy
  multiPermissions: PropTypes.arrayOf(
    PropTypes.shape({
      permissionName: PropTypes.string,
      resourceId: PropTypes.string,
      swimlanes: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  // path to redirect to
  to: PropTypes.string,

  // userHasPermission populated from permissionChecker hoc
  userHasPermission: PropTypes.func.isRequired,

  // ~ bound action creator ~
  push: PropTypes.func.isRequired,
}

const withConnect = connect(null, dispatch =>
  bindActionCreators({ push }, dispatch)
)

export default compose(
  permissionChecker,
  withConnect
)(RedirectWithoutPermission)
