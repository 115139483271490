import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import TextLink from 'components/TextLink'
import { Input, Label } from 'reactstrap'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { isValidURL } from 'utils/sonraiUtils'

class CreateBotModal extends Component {
  state = {
    sourceUrl: '',
  }
  styles = {
    modalBody: {
      maxHeight: '80vh',
      overflow: 'auto',
    },
    error: {
      fontSize: '0.8rem',
      fontWeight: '400',
      margin: '0.25rem 0rem 0.5rem 0.25rem',
      color: this.props.theme.red,
    },
    success: {
      fontSize: '0.8rem',
      fontWeight: '400',
      margin: '0.25rem 0rem 0.5rem 0.25rem',
    },
  }

  handleAddBot = () => {
    this.props.validateSourceUrl({ url: this.state.sourceUrl })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isValid !== undefined) {
      if (prevState.sourceUrl !== this.state.sourceUrl) {
        this.props.clearSourceUrlValidation()
      }
      if (prevProps.isValid !== this.props.isValid && this.props.isValid) {
        this.handleClose()
        this.props.toggle()
      }
    }
    if (
      prevState.focused &&
      !this.state.focused &&
      this.state.sourceUrl !== ''
    ) {
      this.checkURL(this.state.sourceUrl)
    }
    if (!prevState.focused && this.state.focused) {
      this.setState({
        warningText: undefined,
        canSubmit: undefined,
        color: undefined,
      })
    }
  }

  checkURL = url => {
    const isURLFormatted = isValidURL(url)
    if (!isURLFormatted) {
      this.setState({
        canSubmit: false,
        warningText: 'Must be a valid URL',
        color: this.props.theme.red,
      })
    }
    if (isURLFormatted) {
      const endOfStr = url.substr(url.lastIndexOf('/') + 1)
      const hasFileExt = endOfStr.includes('.')
      if (hasFileExt) {
        if (endOfStr === 'manifest.yaml') {
          this.setState({ canSubmit: true })
        } else {
          this.setState({
            warningText: 'The filename must be "manifest.yaml"',
            canSubmit: false,
            color: this.props.theme.red,
          })
        }
      } else {
        this.setState({
          warningText: 'This directory must contain a "manifest.yaml" file',
          canSubmit: true,
          color: this.props.theme.warn,
        })
      }
    }
  }

  handleClose = () => {
    this.setState({
      sourceType: null,
      sourceUrl: '',
    })
    this.props.clearSourceUrlValidation()
  }

  render() {
    const { sourceUrl, focused, warningText, canSubmit } = this.state
    const { isValid, isValidating } = this.props
    const canAdd = sourceUrl !== '' && !isValidating && !focused && canSubmit
    const isNotValid = isValid !== undefined && isValid === false
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        size="lg"
        onClosed={this.handleClose}
      >
        <ModalHeader toggle={this.props.toggle}>Add a Bot </ModalHeader>
        <ModalBody style={this.styles.modalBody}>
          <div style={{ margin: '0rem 0rem 1rem 0rem' }}>
            <Label for="sourceType">Source Url</Label>
            {warningText && (
              <div
                style={{
                  color: this.state.color,
                  fontSize: '0.9rem',
                }}
              >
                * {warningText}
              </div>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Input
                onFocus={() => this.setState({ focused: true })}
                onBlur={() => this.setState({ focused: false })}
                type="text"
                onChange={e => this.setState({ sourceUrl: e.target.value })}
                value={this.state.sourceUrl}
              />
            </div>
            {isNotValid && (
              <div style={this.styles.error}> Not a valid source URL. </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <TextLink color="primary" onClick={this.props.toggle}>
            Cancel
          </TextLink>
          <Button
            disabled={!canAdd}
            color="primary"
            onClick={this.handleAddBot}
          >
            {isValidating ? <Icon fa name="sync" spin /> : 'Add Bot'}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

CreateBotModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  validateSourceUrl: PropTypes.func,
  isValid: PropTypes.bool,
  isValidating: PropTypes.bool,
  clearSourceUrlValidation: PropTypes.func,
  theme: themeShape,
}

export default themeable(CreateBotModal)
